/* global Component */
class ContactUsPage extends Component {

    static  name() {
        return "contactUs";
    }

    static componentName() {
        return "contactUs";
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container">
                      <div class="section-main mb-4">
                          <h5 class="text-center">Contáctenos</h5>
                          <hr>
                          <div class="text-center">
                              <p class="mb-1">
                                  <strong>Emails: </strong>
                                  <a href="mailto:info@ofidirect.com" style="color: #777;">Info</a> •
                                  <a href="mailto:catalogo@ofidirect.com" style="color: #777;">Catálogo</a> •
                                  <a href="mailto:rrhh@ofidirect.com" style="color: #777;">RRHH</a> •
                                  <a href="mailto:ventas@ofidirect.com" style="color: #777;">Ventas</a> •
                                  <a href="mailto:compras@ofidirect.com" style="color: #777;">Compras</a> •
                              </p>
                              <p class="mb-1">
                                  <strong>Teléfono: </strong>
                                  4876-5555
                              </p>
                              <p class="mb-1">
                                  <strong>Dirección: </strong>
                                  California 1955
                              </p>
                          </div>
                          <div class="text-center mt-4">
                              <iframe width="790" height="480" style="max-width: 100%; height: 480px;" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com.ar/maps?f=q&amp;source=s_q&amp;hl=es-419&amp;geocode=&amp;q=California+1955,+Buenos+Aires&amp;aq=0&amp;oq=California+1955&amp;sll=-38.452918,-63.598921&amp;sspn=41.322976,75.234375&amp;t=m&amp;ie=UTF8&amp;hq=&amp;hnear=California+1955,+Barracas,+Buenos+Aires&amp;ll=-34.647808,-58.375919&amp;spn=0.008473,0.013733&amp;z=14&amp;output=embed">
                              </iframe>
                          </div>
                          <p class="text-center mt-2">
                              <small>
                                  <a  href="https://maps.google.com.ar/maps?f=q&amp;source=embed&amp;hl=es-419&amp;geocode=&amp;q=California+1955,+Buenos+Aires&amp;aq=0&amp;oq=California+1955&amp;sll=-38.452918,-63.598921&amp;sspn=41.322976,75.234375&amp;t=m&amp;ie=UTF8&amp;hq=&amp;hnear=California+1955,+Barracas,+Buenos+Aires&amp;ll=-34.647808,-58.375919&amp;spn=0.008473,0.013733&amp;z=14"
                                      style="color: #777; text-decoration: underline; text-underline-position: under;">
                                      Ver mapa más grande
                                  </a>
                              </small>
                          </p>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

ContactUsPage.registerComponent();